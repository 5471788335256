<script>
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
</script>
<template>
  <v-sheet :color="color" elevation="0" height="120"></v-sheet>
</template>
<script>
export default {
  name: "card-status",
  props: {
    color: {
      type: String,
      default: () => "white",
    },
  },
};
</script>
<style lang=""></style>
