<script>
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
</script>
<template>
  <div>
    <div class="d-flex mx-3 my-1">
      <div>Filters</div>
      <v-spacer></v-spacer>

      <v-text-field
        name="name"
        label="Filter by Status"
        outlined
        dense
        flat
        id="id"
      ></v-text-field>
      <v-text-field
        name="name"
        class="mx-1"
        label="Filter by Status"
        outlined
        dense
        flat
        id="id"
      ></v-text-field>
      <v-text-field
        name="name"
        label="Filter by Status"
        outlined
        flat
        dense
        id="id"
      ></v-text-field>
    </div>
    <v-row no-gutters>
      <v-col cols="12" sm="3" class="px-1">
        <card-status :color="'blue'" />
      </v-col>
      <v-col cols="12" sm="3" class="px-1">
        <card-status :color="'yellow'" />
      </v-col>
      <v-col cols="12" sm="3" class="px-1">
        <card-status :color="'green'" />
      </v-col>
      <v-col cols="12" sm="3" class="px-1">
        <card-status :color="'red'" />
      </v-col>
    </v-row>
  </div>
</template>
<script>
import CardStatus from "./components/CardStatus.vue";
export default {
  components: { CardStatus },
  name: "other-dashboard",
  data() {
    return {
      loading: false,
    };
  },
  mounted() {
    this.$parent.$parent.$parent.topBar = true;

    if (this.$vuetify.breakpoint.mobile == false) {
      this.$parent.$parent.$parent.drawer = true;
    }
  },
};
</script>
<style lang=""></style>
